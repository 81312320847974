import React from 'react';

function MaterialDidactico() {
  return (
    <div>
      <h2>Material Didáctico</h2>
      <p>Recursos y material didáctico para el aprendizaje de idiomas.</p>
    </div>
  );
}

export default MaterialDidactico;
