import React from 'react';

function Juegos() {
  return (
    <div>
      <h2>Juegos</h2>
      <p>Material de juegos didácticos.</p>
    </div>
  );
}

export default Juegos;
